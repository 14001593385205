import React, { useState } from 'react';
import styled from 'styled-components';
import axios from 'axios';

const Container = styled.div`
  padding: 20px;
  background-color: #f7f7f7;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  max-width: 800px;
  margin: auto;
`;

const Title = styled.h2`
  text-align: center;
  margin-bottom: 20px;
`;

const SectionTitle = styled.h3`
  margin-top: 20px;
`;

const Label = styled.label`
  display: flex;
  justify-content: space-between;
  margin: 10px 0;
`;

const RadioGroup = styled.div`
  display: flex;
  gap: 20px;
  align-items: center;
`;

const Button = styled.button`
  padding: 10px;
  background-color: #4caf50;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  margin-top: 20px;

  &:hover {
    background-color: #45a049;
  }
`;

const BiddingTool = () => {
  const [score, setScore] = useState(0);
  const [answers, setAnswers] = useState({});
  const userId = localStorage.getItem('userId'); // Replace with actual user ID

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(`/tools/save-bidding-results/${userId}`, {
        totalScore: score
      });
      
      if (response.status === 200) {
        alert('Bidding Tool result saved successfully');
      }
    } catch (error) {
      console.error('Error saving bidding tool result:', error);
    }
  };

  const questions = {
    section1: [
      { question: 'Have we previously worked with this engineer?', points: { yes: 1, no: -1 } },
      { question: 'Are the design documents complete?', points: { yes: 2, no: -2 } },
      { question: 'Is the design free of readily apparent problems?', points: { yes: 3, no: -3 } },
      { question: 'Is there a reasonable procedure for the administration of change orders?', points: { yes: 1, no: -1 } },
      { question: 'Is there a reasonable procedure for the administration of punch-list items?', points: { yes: 1, no: -1 } },
      { question: 'Is there a reasonable process for the administration/approval of shop drawings and submittals?', points: { yes: 1, no: -1 } },
    ],
    section2: [
      { question: 'Does the GC/CM treat bids ethically during the bidding process?', points: { yes: 2, no: -2 } },
      { question: 'Does the GC/CM have a good reputation with electrical contractors?', points: { yes: 2, no: -2 } },
      { question: 'Does the GC/CM have the ability to manage the project scope?', points: { yes: 3, no: -3 } },
      { question: 'Does the GC/CM make progress payments on time?', points: { yes: 1, no: -1 } },
      { question: 'Is additional work expected to be performed?', points: { yes: 1, no: -1 } },
      { question: 'Does the GC/CM coordinate effectively between trades?', points: { yes: 2, no: -2 } },
      { question: 'Does the GC/CM have a realistic expectation of project completion time?', points: { yes: 1, no: -1 } },
    ],
    section3: [
      { question: 'Does the language of the Bid Form require the assumption of major risk?', points: { yes: 4, no: -4 } },
      { question: 'Is the language of the bid clear about what is included/excluded in the scope of work?', points: { yes: 4, no: -4 } },
      { question: 'Is the time allowed for bid preparation proportional to the complexity of the project?', points: { yes: 2, no: -2 } },
      { question: 'Are there enough estimating personnel available to complete bid preparation in the allocated time?', points: { yes: 1, no: -1 } },
      { question: 'Does the bidding process create risk/opportunity (new market, new client, etc)?', points: { yes: 2, no: -2 } },
    ],
    section4: [
      { question: 'Is the number of bidders on the list an advantage or disadvantage?', points: { yes: 1, no: -1 } },
      { question: 'Have we been successful in prior bidding encounters with the contractors on the bidders list?', points: { yes: 2, no: -2 } },
      { question: 'Do we feel we have a reasonable chance of being awarded the contract?', points: { yes: 3, no: -3 } },
      { question: 'Are the competitors for this project Union or Merit Shop?', points: { yes: 3, no: -3 } },
    ],
    section5: [
      { question: 'Is this a bidding opportunity (new market, new partnership, new area)?', points: { yes: 2, no: -2 } },
      { question: 'Are we familiar with this type of project?', points: { yes: 2, no: -2 } },
      { question: 'Are we comfortable with this size of project?', points: { yes: 2, no: -2 } },
      { question: 'Has this type of work been reasonably profitable in the past?', points: { yes: 4, no: -4 } },
      { question: 'Do we have past cost data available from similar projects?', points: { yes: 3, no: -3 } },
      { question: 'Is this "our kind" of project?', points: { yes: 2, no: -2 } },
      { question: 'Do unique construction methods create a risk/opportunity?', points: { yes: 4, no: -4 } },
      { question: 'Does the project require hazardous work (asbestos, contaminated soils)?', points: { yes: 4, no: -4 } },
      { question: 'Does the completion date allow for a realistic schedule?', points: { yes: 4, no: -4 } },
      { question: 'If the project requires complex phasing, is this a risk or opportunity?', points: { yes: 2, no: -2 } },
      { question: 'Does the project expose us to unusual safety risks?', points: { yes: 3, no: -3 } },
      { question: 'Is the project of adequate size for us to be competitive?', points: { yes: 2, no: -2 } },
      { question: 'Has the client set a realistic budget?', points: { yes: 3, no: -3 } },
      { question: 'Do we need the project for our personnel/management?', points: { yes: 4, no: -4 } },
      { question: 'Is our manpower sufficient to complete the project?', points: { yes: 3, no: -3 } },
    ],
    section6: [
      { question: 'Is this an area we are familiar with?', points: { yes: 2, no: -2 } },
      { question: 'Does the location create risk or opportunity?', points: { yes: 2, no: -2 } },
      { question: 'Does the project location create risk in terms of distance/housing?', points: { yes: 1, no: -1 } },
      { question: 'Are there unusual transportation problems at the site?', points: { yes: 1, no: -1 } },
      { question: 'Does the labor market create risk or opportunity?', points: { yes: 4, no: -4 } },
      { question: 'Are there partners available for joint ventures?', points: { yes: 3, no: -3 } },
      { question: 'Are there subcontractors available in the area?', points: { yes: 3, no: -3 } },
      { question: 'Are there restrictive union practices?', points: { yes: 2, no: -2 } },
      { question: 'Are there other large projects competing for labor in the area?', points: { yes: 4, no: -4 } },
      { question: 'Are there unusual IBEW agreements in place?', points: { yes: 3, no: -3 } },
      { question: 'Do anticipated weather conditions create risk or opportunity?', points: { yes: 3, no: -3 } },
      { question: 'Are we currently licensed to conduct business in the project area?', points: { yes: 1, no: -1 } },
    ],
    section7: [
      { question: 'Do we have adequate project management personnel available?', points: { yes: 5, no: -5 } },
      { question: 'Do we have the necessary equipment available?', points: { yes: 1, no: -1 } },
      { question: 'Is there a Project Labor Agreement (PLA) in place?', points: { yes: 2, no: -2 } },
      { question: 'Are project supervisors available for bidding?', points: { yes: 1, no: -1 } },
    ],
    section8: [
      { question: 'Is the contract reasonable and fair?', points: { yes: 4, no: -4 } },
      { question: 'Will further contracts/amendments follow?', points: { yes: 2, no: -2 } },
      { question: 'Is the warranty for the project reasonable?', points: { yes: 2, no: -2 } },
      { question: 'Is the basis for payment acceptable?', points: { yes: 3, no: -3 } },
      { question: 'Is the rate of retainage nonstandard or unusual?', points: { yes: 2, no: -2 } },
      { question: 'Do liquidated damages create risk or opportunity?', points: { yes: 5, no: -5 } },
      { question: 'Are there insurance/indemnification requirements that create risk?', points: { yes: 2, no: -2 } },
    ],
    section9: [
      { question: 'Is the work around an operating unit?', points: { yes: 3, no: -3 } },
      { question: 'Is there adequate space to store materials?', points: { yes: 4, no: -4 } },
      { question: 'Is overtime required?', points: { yes: 2, no: -2 } },
      { question: 'Is shiftwork required?', points: { yes: 1, no: -1 } },
      { question: 'Is the owner supplying materials?', points: { yes: 4, no: -4 } },
      { question: 'Is the owner supplying major equipment?', points: { yes: 1, no: -1 } },
      { question: 'Are there access constraints to the site?', points: { yes: 4, no: -4 } },
    ],
  };

  
  const handleChange = (section, index, answer) => {
    const updatedAnswers = { ...answers, [`${section}-${index}`]: answer };
    setAnswers(updatedAnswers);
    calculateScore(updatedAnswers);
  };

  const calculateScore = (updatedAnswers) => {
    let totalScore = 0;

    Object.keys(questions).forEach((section) => {
      questions[section].forEach((q, index) => {
        const answer = updatedAnswers[`${section}-${index}`];
        if (answer) {
          totalScore += q.points[answer];
        }
      });
    });

    setScore(totalScore);
  };

  return (
    <Container>
      <Title>Bidding Tool</Title>
      <form onSubmit={handleSubmit}>
        {Object.keys(questions).map((section, sectionIndex) => (
          <div key={sectionIndex}>
            <SectionTitle>{`Section ${sectionIndex + 1}`}</SectionTitle>
            {questions[section].map((q, index) => (
              <Label key={index}>
                {q.question}
                <RadioGroup>
                  <label>
                    <input
                      type="radio"
                      name={`${section}-${index}`}
                      value="yes"
                      onChange={() => handleChange(section, index, 'yes')}
                    />{' '}
                    Yes
                  </label>
                  <label>
                    <input
                      type="radio"
                      name={`${section}-${index}`}
                      value="no"
                      onChange={() => handleChange(section, index, 'no')}
                    />{' '}
                    No
                  </label>
                </RadioGroup>
              </Label>
            ))}
          </div>
        ))}
        <Button type="submit">Submit and Save Score</Button>
      </form>
      <div>Total Score: {score}</div>
    </Container>
  );
};

export default BiddingTool;
