

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import styled from 'styled-components';

const Container = styled.div`
  padding: 20px;
  background-color: #f7f7f7;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  max-width: 800px;
  margin: auto;
`;

const Title = styled.h2`
  text-align: center;
  margin-bottom: 20px;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const InputGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const Label = styled.label`
  font-weight: bold;
`;

const Input = styled.input`
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ddd;
  font-size: 16px;
`;

const Button = styled.button`
  padding: 10px;
  background-color: #4caf50;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;

  &:hover {
    background-color: #45a049;
  }
`;

const Select = styled.select`
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ddd;
  font-size: 16px;
`;

const Tooltip = styled.span`
  visibility: hidden;
  width: 300px;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -150px;
  opacity: 0;
  transition: opacity 0.3s;

  &:after {
    content: '';
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #555 transparent transparent transparent;
  }
`;

const FactorLabel = styled.label`
  position: relative;
  display: inline-block;
  cursor: pointer;
  
  &:hover ${Tooltip} {
    visibility: visible;
    opacity: 1;
  }
`;

const Results = styled.div`
  margin-top: 20px;
  padding: 20px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

const TableHeader = styled.th`
  border: 1px solid #ddd;
  padding: 8px;
  background-color: #f2f2f2;
  text-align: center;
`;

const TableCell = styled.td`
  border: 1px solid #ddd;
  padding: 8px;
  text-align: center;
`;

const productivityFactors = {
  "Overmanning": {
    none: 0.00, minor: 0.22, moderate: 0.23, severe: 0.34,
    description: "An increase of the peak number of workers of the same trade above the planned or estimated peak. Estimated peak typically equals to 1.6 times the average number of workers."
  },
  "Overtime 5-10": {
    none: 0.00, minor: 0.05, moderate: 0.20, severe: 0.30,
    description: "The work that exceeds 8 hours/day and 40 hours/week. (10 hrs/day, 5 days/week)."
  },
  "Overtime 6-10": {
    none: 0.00, minor: 0.04, moderate: 0.20, severe: 0.40,
    description: "The work that exceeds 8 hours/day and 40 hours/week. (10 hrs/day, 6 days/week)."
  },
  "Second Shift": {
    none: 0.00, minor: -0.05, moderate: 0.10, severe: 0.17,
    description: "The hours worked by a second group of craftsmen whose work is performed after the first work force of the same trade has retired for the day."
  },
  "Stacking of Trades": {
    none: 0.00, minor: 0.24, moderate: 0.28, severe: 0.32,
    description: "Considers the total number of craftsmen from all trades working in a given area. Worker density depends on the available net working area."
  },
  "Out-of-Sequence work": {
    none: 0.00, minor: 0.13, moderate: 0.31, severe: 0.28,
    description: "An activity or a series of activities that are not performed according to baseline logical and productive planned sequence."
  },
  "Weather": {
    none: 0.00, minor: 0.00, moderate: 0.20, severe: 0.40,
    description: "Impact of temperature and humidity on labor output."
  },
  "Owner-Furnished Items": {
    none: 0.00, minor: 0.04, moderate: 0.11, severe: 0.18,
    description: "Includes both material and equipment that are bought and supplied to the specialty contractor by the owner or general contractor."
  },
  "Access Constraints": {
    none: 0.00, minor: 0.17, moderate: 0.25, severe: 0.32,
    description: "Access constraints are experienced when the work area is unavailable, partially available, or not in a condition to be used by workers."
  },
  "Beneficial Occupancy": {
    none: 0.00, minor: 0.12, moderate: 0.20, severe: 0.28,
    description: "A situation in which a contractor must work in close proximity to an owner’s production equipment or personnel."
  },
  "Loss of Learning": {
    none: 0.00, minor: 0.21, moderate: 0.29, severe: 0.32,
    description: "Loss of Learning occurs when highly repetitive operations are interrupted or personnel are changed, with new personnel slowly learning the operations."
  },
  "Change Order Processing Time": {
    none: 0.00, minor: 0.00, moderate: 0.10, severe: 0.15,
    description: "The period of time between initiation of the change order and the owner’s approval for the majority of change items."
  },
  "Absenteeism": {
    none: 0.00, minor: 0.02, moderate: 0.22, severe: 0.40,
    description: "Impact of absenteeism rates on labor productivity."
  },
  "Turnover": {
    none: 0.00, minor: 0.04, moderate: 0.10, severe: 0.20,
    description: "Impact of turnover rates on labor productivity."
  },
  "Dilution of Supervision": {
    none: 0.00, minor: 0.25, moderate: 0.28, severe: 0.31,
    description: "Occurs when supervisors are diverted from productive work as a result of high crew levels and change order work."
  },
  "Percent Design Complete Prior to Construction": {
    none: 0.00, minor: 0.01, moderate: 0.10, severe: 0.18,
    description: "Percentage of the design complete prior to construction."
  },
  "AE Coordination Prior to Construction": {
    none: 0.00, minor: 0.02, moderate: 0.08, severe: 0.14,
    description: "Coordination with the architect/engineer prior to construction."
  },
  "AE Support During Construction": {
    none: 0.00, minor: 0.01, moderate: 0.10, severe: 0.20,
    description: "Support provided by the architect/engineer during construction."
  },
  "Manpower Shortage During Construction": {
    none: 0.00, minor: 0.02, moderate: 0.08, severe: 0.14,
    description: "Impact of manpower shortage during construction."
  },
};

const ProductivityLossCalculator = ({ setProductivityResults }) => {
  const [numWeeks, setNumWeeks] = useState(5);
  const [weeks, setWeeks] = useState(['Week 1', 'Week 2', 'Week 3', 'Week 4', 'Week 5']);
  const [hoursData, setHoursData] = useState({});
  const [calculationType, setCalculationType] = useState('proactive');
  const [selectedFactors, setSelectedFactors] = useState([]);
  const [chosenFactors, setChosenFactors] = useState([]);
  const [results, setResults] = useState(null);
  
  const userId = localStorage.getItem('userId');
  const factors = [
    'Overmanning',
    'Overtime 5-10',
    'Overtime 6-10',
    'Second Shift',
    'Stacking of Trades',
    'Out-of-Sequence work',
    'Weather',
    'Owner-Furnished Items',
    'Access Constraints',
    'Beneficial Occupancy',
    'Loss of Learning',
    'Change Order Processing Time',
    'Absenteeism',
    'Turnover',
    'Dilution of Supervision',
    'Percent Design Complete Prior to Construction',
    'AE Coordination Prior to Construction',
    'AE Support During Construction',
    'Manpower Shortage During Construction',
  ];

  useEffect(() => {
    const newWeeks = Array.from({ length: numWeeks }, (_, i) => `Week ${i + 1}`);
    const newHoursData = newWeeks.reduce((acc, week) => {
      acc[week] = {
        plannedWorkHours: 0,
        changeOrderHours: 0,
        revisedWorkHours: 0,
      };
      return acc;
    }, {});
    setWeeks(newWeeks);
    setHoursData(newHoursData);
  }, [numWeeks]);

  const handleInputChange = (week, field, value) => {
    setHoursData((prev) => {
      const updatedWeekData = {
        ...prev[week],
        [field]: parseFloat(value) || 0,
      };
      updatedWeekData.revisedWorkHours = updatedWeekData.plannedWorkHours + updatedWeekData.changeOrderHours;
      return {
        ...prev,
        [week]: updatedWeekData,
      };
    });
  };

  const handleNumWeeksChange = (e) => {
    const value = parseInt(e.target.value);
    setNumWeeks(value);
  };

  const handleFactorSelection = (factor) => {
    setChosenFactors((prev) => {
      if (prev.includes(factor)) {
        return prev.filter(f => f !== factor);
      }
      if (prev.length < 3) {
        return [...prev, factor];
      }
      return prev;
    });
  };

  const handleFactorChange = (week, factor, severity) => {
    setSelectedFactors((prev) => {
      const factorIndex = prev.findIndex((f) => f.week === week && f.factor === factor);
      if (factorIndex >= 0) {
        const newFactors = [...prev];
        newFactors[factorIndex] = { week, factor, severity };
        return newFactors;
      } else {
        return [...prev, { week, factor, severity }];
      }
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
        let totalPlannedHours = 0;
        let totalChangeOrderHours = 0;
        let totalRevisedHours = 0;
        let totalFactors = 0;
        let totalLoss = 0;
   	const userId = 1;

        const weekResults = weeks.map((week) => {
            const plannedWorkHours = hoursData[week]?.plannedWorkHours || 0;
            const changeOrderHours = hoursData[week]?.changeOrderHours || 0;
            const revisedWorkHours = hoursData[week]?.revisedWorkHours || 0;

            let weekFactors = 0;
            chosenFactors.forEach((factor) => {
                const severity = selectedFactors.find(f => f.week === week && f.factor === factor)?.severity || 'none';
                weekFactors += productivityFactors[factor][severity];
            });

            let estimatedLossOfProductivity;
            if (calculationType === 'retroactive') {
                // Apply the retroactive formula
                estimatedLossOfProductivity = revisedWorkHours - (revisedWorkHours / (1 + weekFactors));
            } else {
                // Apply the proactive formula
                estimatedLossOfProductivity = weekFactors * revisedWorkHours;
            }

            totalPlannedHours += plannedWorkHours;
            totalChangeOrderHours += changeOrderHours;
            totalRevisedHours += revisedWorkHours;
            totalFactors += weekFactors;
            totalLoss += estimatedLossOfProductivity;

            return {
                week,
                plannedWorkHours,
                changeOrderHours,
                revisedWorkHours,
                weekFactors: weekFactors.toFixed(2),
                estimatedLossOfProductivity: estimatedLossOfProductivity.toFixed(2),
                calculationType, // Include the calculation type in each week's result
            };
        });

        const result = {
            weekResults,
            totalPlannedHours,
            totalChangeOrderHours,
            totalRevisedHours,
            totalFactors: totalFactors.toFixed(2),
            totalLoss: totalLoss.toFixed(2),
        };

        setResults(result);

        // Post data to the backend
        const response = await axios.post(`/tools/calculate-productivity-loss/${userId}`, {

            result,
	    userId
        });

        if (response.status === 200) {
            console.log('Data successfully saved');
        }

    } catch (error) {
        console.error('Error calculating productivity loss:', error.message);
    	console.error('Error details:', error.response.data);
    }
};



  return (
    <Container>
      <Title>Productivity Loss Calculator</Title>
      <Form onSubmit={handleSubmit}>
        <InputGroup>
          <Label>Number of Weeks</Label>
          <Input type="number" value={numWeeks} onChange={handleNumWeeksChange} min="1" max="52" />
        </InputGroup>
        <InputGroup>
          <Label>Select Calculation Type</Label>
          <Select value={calculationType} onChange={(e) => setCalculationType(e.target.value)}>
            <option value="proactive">Proactive</option>
            <option value="retroactive">Retroactive</option>
          </Select>
        </InputGroup>
        {factors.map((factor) => (
          <div key={factor}>
            <input
              type="checkbox"
              value={factor}
              checked={chosenFactors.includes(factor)}
              onChange={() => handleFactorSelection(factor)}
              disabled={chosenFactors.length >= 3 && !chosenFactors.includes(factor)}
            />
            <FactorLabel>
              {factor}
              <Tooltip>{productivityFactors[factor].description}</Tooltip>
            </FactorLabel>
          </div>
        ))}
        {weeks.map((week) => (
          <InputGroup key={week}>
            <Label>{week}</Label>
            <Input
              type="number"
              placeholder="Planned Work Hours"
              onChange={(e) => handleInputChange(week, 'plannedWorkHours', e.target.value)}
            />
            <Input
              type="number"
              placeholder="Change Order Hours"
              onChange={(e) => handleInputChange(week, 'changeOrderHours', e.target.value)}
            />
            <Input
              type="number"
              placeholder="Revised Work Hours"
              value={hoursData[week]?.revisedWorkHours || 0}
              readOnly
            />
            {chosenFactors.map((factor) => (
              <div key={factor}>
                <Label>{factor}</Label>
                <Select onChange={(e) => handleFactorChange(week, factor, e.target.value)}>
                  <option value="none">None</option>
                  <option value="minor">Minor</option>
                  <option value="moderate">Moderate</option>
                  <option value="severe">Severe</option>
                </Select>
              </div>
            ))}
          </InputGroup>
        ))}
        <Button type="submit">Calculate Productivity Loss</Button>
      </Form>
      {results && (
        <Results>
          <h3>Results</h3>
          <Table>
            <thead>
              <tr>
                <TableHeader>Week</TableHeader>
                <TableHeader>Planned Hours</TableHeader>
                <TableHeader>Change Order Hours</TableHeader>
                <TableHeader>Revised Hours</TableHeader>
                <TableHeader>Factors</TableHeader>
                <TableHeader>Estimated Loss of Productivity</TableHeader>
                <TableHeader>Calculation Type</TableHeader> {/* Add header for calculation type */}
              </tr>
            </thead>
            <tbody>
              {results.weekResults.map((weekResult, index) => (
                <tr key={index}>
                  <TableCell>{weekResult.week}</TableCell>
                  <TableCell>{weekResult.plannedWorkHours}</TableCell>
                  <TableCell>{weekResult.changeOrderHours}</TableCell>
                  <TableCell>{weekResult.revisedWorkHours}</TableCell>
                  <TableCell>{weekResult.weekFactors}</TableCell>
                  <TableCell>{weekResult.estimatedLossOfProductivity}</TableCell>
                  <TableCell>{weekResult.calculationType}</TableCell> {/* Display calculation type */}
                </tr>
              ))}
              <tr>
                <TableCell>Total</TableCell>
                <TableCell>{results.totalPlannedHours}</TableCell>
                <TableCell>{results.totalChangeOrderHours}</TableCell>
                <TableCell>{results.totalRevisedHours}</TableCell>
                <TableCell></TableCell>
                <TableCell>{results.totalLoss}</TableCell>
                <TableCell></TableCell>
              </tr>
            </tbody>
          </Table>
        </Results>
      )}
    </Container>
  );
};

export default ProductivityLossCalculator;
