import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import GlobalStyle from './GlobalStyles';
import Navbar from './components/Navbar';
import Home from './pages/Home';
import SignInPage from './pages/SignInPage';
import SignUpPage from './pages/SignUpPage';
import Tools from './components/Tools';
import ProfilePage from './components/ProfilePage'
import './styles.css';
import axios from 'axios';

axios.defaults.baseURL = 'https://hcgtools.com/api';
function App() {
  const [auth, setAuth] = useState(null);

  useEffect(() => {
    const token = localStorage.getItem('token');
    const user = localStorage.getItem('user');
    if (token && user) {
      setAuth({ token, user: JSON.parse(user) });
    }
  }, []);

  return (
    <Router>
      <GlobalStyle />
      <Navbar auth={auth} setAuth={setAuth} />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/signin" element={<SignInPage setAuth={setAuth} />} />
        <Route path="/signup" element={<SignUpPage />} />
        <Route path="/tools" element={<Tools />} />
        <Route path="/profile" element={<ProfilePage />} />
      </Routes>
    </Router>
  );
}

export default App;
