import React from 'react';
import './Hero.css';

const Hero = () => {
  return (
    <div className="hero-container">
      <h1>HCGWAY</h1>
      <p>Dr Hanna's Consulting Group.</p>
    </div>
  );
};

export default Hero;
