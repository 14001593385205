import React, { useState } from 'react';
import ProductivityLossCalculator from '../components/ProductivityLossCalculator';
import BiddingTool from '../components/BiddingTool';

const Tools = () => {
  const [selectedTool, setSelectedTool] = useState('productivity'); // Default to productivity tool

  return (
    <div>
      <h2>Tools</h2>
      <div>
        <label>Select Tool: </label>
        <select value={selectedTool} onChange={(e) => setSelectedTool(e.target.value)}>
          <option value="productivity">Productivity Loss Calculator</option>
          <option value="bidding">Bidding Tool</option>
        </select>
      </div>

      {selectedTool === 'productivity' && <ProductivityLossCalculator />}
      {selectedTool === 'bidding' && <BiddingTool />}
    </div>
  );
};

export default Tools;
