import React from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

const Nav = styled.nav`
  height: 60px;
  background: #333;
  color: #fff;
  display: flex;
  justify-content: space-between;
  padding: 0 20px;
  align-items: center;
  z-index: 1000;
  position: relative;
`;

const NavMenu = styled.div`
  display: flex;
  align-items: center;
`;

const NavItem = styled(NavLink)`
  color: #fff;
  text-decoration: none;
  padding: 0 10px;
  &.active {
    font-weight: bold;
  }
`;

const Logo = styled(NavLink)`
  color: #fff;
  text-decoration: none;
  font-size: 24px;
  font-weight: bold;
`;

const LogoutButton = styled.button`
  background-color: #e74c3c;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  cursor: pointer;
  margin-left: 10px;

  &:hover {
    background-color: #c0392b;
  }
`;

const Navbar = ({ auth, setAuth }) => {
  const navigate = useNavigate();

  const handleLogout = () => {
    setAuth(null);
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    navigate('/');
  };

  return (
    <Nav>
      <Logo to="/">HCGTools</Logo>
      <NavMenu>
        {auth ? (
          <>
            <NavItem to="/profile">Profile</NavItem>
            <NavItem to="/tools">Tools</NavItem>
            <LogoutButton onClick={handleLogout}>
              Logout
            </LogoutButton>
          </>
        ) : (
          <>
            <NavItem to="/signin">Sign In</NavItem>
            <NavItem to="/signup">Sign Up</NavItem>
            <NavItem to="/profile" activeClassName="active">
          Profile
        </NavItem>
          </>
        )}
      </NavMenu>
    </Nav>
  );
};

export default Navbar;
