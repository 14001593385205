import React, { useEffect, useState } from 'react';
import axios from 'axios';
import styled from 'styled-components';

const Container = styled.div`
  padding: 20px;
  background-color: #f7f7f7;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  max-width: 800px;
  margin: auto;
`;

const Title = styled.h2`
  text-align: center;
  margin-bottom: 20px;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
`;

const TableHeader = styled.th`
  border: 1px solid #ddd;
  padding: 8px;
  background-color: #f2f2f2;
  text-align: center;
`;

const TableCell = styled.td`
  border: 1px solid #ddd;
  padding: 8px;
  text-align: center;
`;

const Button = styled.button`
  padding: 10px;
  background-color: #f44336;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  margin-top: 20px;

  &:hover {
    background-color: #e53935;
  }
`;

const ProfilePage = () => {
  const [data, setData] = useState([]);
  const [biddingData, setBiddingData] = useState([]); // Added state for bidding data

  const userId = 1; // Replace with the actual user ID

  const fetchData = async () => {
    try {
      const response = await axios.get(`/tools/productivity-loss/${userId}`);
      setData(response.data);
    } catch (error) {
      console.error('Error fetching productivity loss data:', error);
    }
  };

  const fetchBiddingData = async () => { // Added fetch for bidding data
    try {
      const response = await axios.get(`/tools/bidding-results/${userId}`);
      setBiddingData(response.data);
    } catch (error) {
      console.error('Error fetching bidding tool data:', error);
    }
  };

  const handleClearData = async () => {
    try {
      await axios.delete(`/tools/clear-data/${userId}`);
      setData([]); // Clear data locally after successful deletion
    } catch (error) {
      console.error('Error clearing productivity loss data:', error);
    }
  };

  const handleClearBiddingData = async () => {
    try {
      await axios.delete(`/tools/clear-bidding-data/${userId}`);
      setBiddingData([]); // Clear bidding data locally after successful deletion
      alert('Bidding tool data cleared successfully');
    } catch (error) {
      console.error('Error clearing bidding tool data:', error);
    }
  };
  

  useEffect(() => {
    fetchData();
    fetchBiddingData(); // Fetch bidding data when component mounts
  }, []);

  const renderSubmission = (submission) => {
    const sumField = (field) => {
      return submission.reduce((sum, row) => sum + (parseFloat(row[field]) || 0), 0).toFixed(2);
    };

    return (
      <>
        <Table>
          <thead>
            <tr>
              <TableHeader>Week</TableHeader>
              <TableHeader>Planned Hours</TableHeader>
              <TableHeader>Change Order Hours</TableHeader>
              <TableHeader>Revised Hours</TableHeader>
              <TableHeader>Factors</TableHeader>
              <TableHeader>Estimated Loss of Productivity</TableHeader>
              <TableHeader>Calculation Type</TableHeader>
            </tr>
          </thead>
          <tbody>
            {submission.map((row, index) => (
              <tr key={index}>
                <TableCell>{row.week}</TableCell>
                <TableCell>{row.planned_hours}</TableCell>
                <TableCell>{row.change_order_hours}</TableCell>
                <TableCell>{row.revised_hours}</TableCell>
                <TableCell>{row.total_factors}</TableCell>
                <TableCell>{row.estimated_loss_of_productivity}</TableCell>
                <TableCell>{row.calculation_type}</TableCell>
              </tr>
            ))}
            <tr>
              <TableCell>Total</TableCell>
              <TableCell>{sumField('planned_hours')}</TableCell>
              <TableCell>{sumField('change_order_hours')}</TableCell>
              <TableCell>{sumField('revised_hours')}</TableCell>
              <TableCell></TableCell>
              <TableCell>{sumField('estimated_loss_of_productivity')}</TableCell>
              <TableCell></TableCell>
            </tr>
          </tbody>
        </Table>
      </>
    );
  };

  const renderBiddingData = () => { // Added function to render bidding data
    return biddingData.map((submission, index) => (
      <div key={index}>
        <h3>Bidding Tool Submission {index + 1}</h3>
        <Table>
          <thead>
            <tr>
              <TableHeader>Submission ID</TableHeader>
              <TableHeader>Total Score</TableHeader>
              <TableHeader>Submission Date</TableHeader>
            </tr>
          </thead>
          <tbody>
            <tr>
              <TableCell>{submission.submission_id}</TableCell>
              <TableCell>{submission.total_score}</TableCell>
              <TableCell>{new Date(submission.submission_date).toLocaleDateString()}</TableCell>
            </tr>
          </tbody>
        </Table>
      </div>
    ));
  };

  const groupedData = data.reduce((acc, row) => {
    const submissionId = row.submission_id;
    if (!acc[submissionId]) {
      acc[submissionId] = [];
    }
    acc[submissionId].push(row);
    return acc;
  }, {});

  return (
    <Container>
      <Title>Productivity Loss Data</Title>
      {Object.keys(groupedData).length > 0 ? (
        <>
          {Object.values(groupedData).map((submission, index) => (
            <div key={index}>
              <h3>Submission {index + 1}</h3>
              {renderSubmission(submission)}
            </div>
          ))}
          <Button onClick={handleClearData}>Clear Productivity Loss Data</Button>
        </>
      ) : (
        <p>No data available.</p>
      )}

      {/* Bidding Tool Data Section */}
      <Title>Bidding Tool Data</Title>
      {biddingData.length > 0 ? (
        <>
          {renderBiddingData()}
          <Button onClick={handleClearBiddingData}>Clear Bidding Tool Data</Button>
        </>
      ) : (
        <p>No bidding data available.</p>
      )}
    </Container>
  );
};

export default ProfilePage;
